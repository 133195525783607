import { authAtom } from '_state';
import { useRecoilValue } from 'recoil';

export { About };

function About() {
    const auth = useRecoilValue(authAtom);

    return (
        <main>
            <section className='pt-7 py-md-9'>
                <div className="container">
                    <div className='row'>
                        <div className='col-md-2'>
                            <hr className='hr-sm bg-primary' />
                            <p className='fs-3'>Disfrútalo presencial o a domicilio</p>
                        </div>
                        <div className='col-md-4'>
                            <img src='/assets/img/Restaurant1v2_hdr_cube_o_1000_1.jpg' alt='' className='img-fluid' />
                        </div>
                        <div className='col-md-5 align-self-center mx-auto'>
                            <h2 className='mb-2'>Experiencia fabulosa</h2>
                            <p className='mb-0'>El restaurante ofrece un ambiente agradable y fresco al contar con aire acondicionado, un espacio ideal para reuniones y festejos con la familia, amigos o en pareja. La carta presenta sugerencias tradicionales e internacionales con alimentos de la más alta calidad a precios razonables. Terra-Cotta le brinda calidad, servicio y un ambiente inigualable en Huatulco.</p>
                        </div>
                    </div>
                </div>
            </section>

            <section className="py-7 py-md-9 bg-light">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-12 col-md-8 text-center">
                            <span className="display-1 font-serif text-primary">”</span>
                            <figure className="mb-0">
                                <blockquote className="blockquote mb-0">
                                    <p className="font-serif text-black mb-6">
                                        Lorem ipsum dolor sit amet, consectetur adipisicing elit. Eos at veritatis vitae modi ex quis quibusdam error repudiandae adipisci dolore perspiciatis iste, vel fuga a, libero architecto ratione deleniti sequi.
                                    </p>
                                </blockquote>
                                <figcaption className="blockquote-footer text-xs mb-0">
                                    Luis Arreola
                                </figcaption>
                            </figure>

                        </div>
                    </div>
                </div>
            </section>

            <section className='py-7 py-md-9'>
                <div className='container'>
                    <div className='row justify-content-center'>
                        <div className='col-12 col-md-12 text-center'>
                            <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d30716.985887565264!2d-96.1386922!3d15.7710582!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x85bf21cf4fb2b631%3A0x3fafc2e05848ee2d!2sTerracota!5e0!3m2!1ses!2smx!4v1653006099041!5m2!1ses!2smx" width="600" height="450" allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>
                        </div>
                    </div>
                </div>
            </section>
        </main>
    );
}
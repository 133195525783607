import { useRecoilState } from "recoil";
import { useFetchWrapper } from "_helpers";
import { authAtom } from "_state";

export { useTokenActions };

function useTokenActions() {
    const baseUrl = `${process.env.REACT_APP_API_URL.replace("/api/v1", "")}/token`;
    const fetchWrapper = useFetchWrapper();
    const [auth, setAuth] = useRecoilState(authAtom);

    return {
        refreshToken
    }

    function refreshToken() {
        let username = 'Yv97WKKBvWhdPntn7fBh333Y';
        let password = 'Uh7YyDLkm2c7dyfuWTt$YtCW';

        if (localStorage.getItem("token")) {
            let tokenTimer = new Date(localStorage.getItem("tokenTimer"));
            let localDate = new Date();

            console.log(Math.abs(localDate.getTime() - tokenTimer.getTime()) / 360000);
            if ((Math.abs(localDate.getTime() - tokenTimer.getTime()) / 360000) < 18) //El token se valida sobre una vigencia de 18 horas
                return Promise.resolve();
        }
        return fetchWrapper.post(baseUrl, { username, password })
            .then(token => {
                localStorage.setItem("token", JSON.stringify(token));
                localStorage.setItem("tokenTimer", new Date())
                setAuth(token);
            });

    }
}
import { authAtom } from '_state';
import { useRecoilValue } from 'recoil';

export { Menu };

function Menu() {
    const auth = useRecoilValue(authAtom);

    return(
        <main>

        </main>
    );
}
export { Home };

function Home() {
    return (
        <main>
            <section className='pt-7 py-md-9'>
                <div className="container">
                    <div className='row'>
                        <div className='col-md-2'>
                            <hr className='hr-sm bg-primary' />
                            <p className='fs-3'>Disfrútalo presencial o a domicilio</p>
                        </div>
                        <div className='col-md-4'>
                            <img src='/assets/img/Restaurant1v2_hdr_cube_o_1000_1.jpg' alt='' className='img-fluid' />
                        </div>
                        <div className='col-md-5 align-self-center mx-auto'>
                            <h2 className='mb-2'>Experiencia fabulosa</h2>
                            <p className='mb-0'>El restaurante ofrece un ambiente agradable y fresco al contar con aire acondicionado, un espacio ideal para reuniones y festejos con la familia, amigos o en pareja. La carta presenta sugerencias tradicionales e internacionales con alimentos de la más alta calidad a precios razonables. Terra-Cotta le brinda calidad, servicio y un ambiente inigualable en Huatulco.</p>
                        </div>
                    </div>
                </div>
            </section>
        </main>
    );
}
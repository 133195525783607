import { useFetchWrapper } from '_helpers';
import { useTokenActions } from './token.actions';

export { useContactActions };

function useContactActions() {
    const baseUrl = `${process.env.REACT_APP_API_URL}/contact`;
    const fetchWrapper = useFetchWrapper();
    const tokenActions = useTokenActions();

    return {
        SendMessage
    }

    function SendMessage(contact) {
        return tokenActions.refreshToken()
            .then(token => {
                return fetchWrapper.post(`${baseUrl}`, contact);
            });
    }
}
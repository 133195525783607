import { Router, Route, Switch, Redirect } from 'react-router-dom';
import { useRecoilValue } from 'recoil';

import { authAtom } from '_state';
import { Nav, Alert, PrivateRoute } from '_components';
import { history } from '_helpers';
import { Home } from 'home';
import { MainHeader, Header } from '_components';
import { Menu } from 'menu';
import { About } from 'about';
import { Contact } from 'contact';

export { App };

function App() {
    const auth = useRecoilValue(authAtom);

    return (
        <div className={'app-container' + (auth ? ' bg-light' : '')}>
            <Router history={history}>
                <Nav />
                <Switch>
                    <Route exact path="/" component={MainHeader} />
                    <Route exact path="/menu">
                        <Header title='Amplia selección de platillos' imageName='MenuHeader.jpeg' />
                    </Route>
                    <Route exact path="/about-us">
                        <Header title='Ven a conocernos' imageName='AboutusHeader.jpeg' />
                    </Route>
                    <Route exact path="/contact">
                        <Header title='Mantente en contacto' imageName='ReservationsHeader.jpg' />                        
                    </Route>
                </Switch>
                <Alert />
                <Switch>
                    <PrivateRoute exact path="/" component={Home} />
                    <Route exact path="/menu" component={Menu} />
                    <Route exact path="/about-us" component={About} />
                    <Route exact path="/contact" component={Contact} />
                    <Redirect from="*" to="/" />
                </Switch>
            </Router>
        </div>
    );
}
import { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';

export { Nav };

function Nav() {
    const location = useLocation();

    const [navbar, setNavBar] = useState(false);
    const [activeMenu, setActiveMenu] = useState(false);
    const [activeAbout, setActiveAbout] = useState(false);
    const [activeContact, setActiveContact] = useState(false);

    useEffect(() => {
        window.addEventListener('scroll', changeBackground);

        return () => {
            window.removeEventListener('scroll', changeBackground);
        }
    }, [window.scrollY]);

    useEffect(() => {
        setActiveMenu(location.pathname.match(/^\/menu/) ? "active" : "");
        setActiveAbout(location.pathname.match(/^\/about/) ? "active" : "");
        setActiveContact(location.pathname.match(/^\/contact/ ? "active" : ""));
    }, [location]);

    const changeBackground = () => {
        setNavBar(window.scrollY >= 80);
    }
    
    return (
        <nav className={`navbar navbar-expand-lg navbar-togglable fixed-top ${ navbar ? 'navbar-light' : 'navbar-dark' }`}>
            <div className="container">
                <div className='collapse navbar-collapse'>
                    <ul className='navbar-nav'>
                        <li className='nav-item'><Link to={"/about-us"} className={'nav-link ' + activeAbout}>CONÓCENOS</Link></li>
                        <li className='nav-item'><Link to={"/menu"} className={'nav-link ' + activeMenu}>NUESTRO MENÚ</Link></li>
                    </ul>
                    <Link to={"/"} className='navbar-brand d-none d-lg-flex mx-lg-auto'>Terra Cotta</Link>
                    <ul className='navbar-nav'>
                        <li className='nav-item'><Link to={"/contact"} className={'nav-link ' + activeContact}>CONTÁCTANOS</Link></li>
                        <li className='nav-item'><a className='nav-link' href='http://misiondelosarcos.website/?lang=es' target={"_blank"}>FACTURACIÓN</a></li>
                    </ul>
                </div>
            </div>
        </nav>
    );
}
import React from 'react';
import { useEffect } from 'react';
import { useRecoilValue } from 'recoil';

import { alertAtom } from '../_state';
import { useAlertActions } from '../_actions';
import { history } from '../_helpers';

export { Alert };

function Alert() {
    const alert = useRecoilValue(alertAtom);
    const alertActions = useAlertActions();

    useEffect(() => {
        // clear alert on location change
        const unlisten = history.listen(alertActions.clear);

        // stop the listener when component unmounts
        return unlisten;

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    if (!alert) return null;

    return (
        <div className="container">
            <div className="m-3">
                <div className={`alert alert-dismissible ${alert.type} fade show`}>
                    {alert.message}
                    <button type="button" class="btn-close" onClick={alertActions.clear}></button>
                </div>
            </div>
        </div>
    );
}
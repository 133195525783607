import { useForm } from 'react-hook-form';
import { useAlertActions, useContactActions } from '_actions';
import { yupResolver } from '@hookform/resolvers/yup'
import * as Yup from 'yup';

export { Contact };

function Contact({ history }) {
    const alertActions = useAlertActions();
    const contactActions = useContactActions();

    const validationSchema = Yup.object().shape({
        fullname: Yup.string()
            .required('Debes escribir tu nombre completo')
            .max(200, 'Nombre demasiado largo no permitido'),
        email: Yup.string()
            .required('Debes escribir un correo electrónico')
            .max(200, 'Correo electrónico demasiado grande no permitido')
            .email('No se reconoce como un correo electrónico válido'),
        message: Yup.string()
            .required('Debes escribir un mensaje')
            .max(2048, 'Mensaje demasiado largo')
    });

    const formOptions = { resolver: yupResolver(validationSchema) };

    const { handleSubmit, register, formState } = useForm(formOptions); 
    const { errors, isSubmitting } = formState;

    function onSubmit(data) {
        return contactActions.SendMessage(data)
            .then(() => {
                history.push('/');
                alertActions.success('Mensaje enviado. Gracias por sus comentarios');
            });
    }

    return (
        <main>
            <section className='pt-7 py-md-9'>
                <div className='container'>
                    <div className='row justify-content-center'>
                        <div className='col-12 col-md-8 col-lg-6 text-center'>
                            <h2 className='mb-2'>Contáctanos cuando lo desees</h2>
                            <p className='mb-6'>
                                Puedes escribirnos cuando tengas dudas y/o comentarios sobre nuestros servicios o por si quieres conocernos. Todos los comentarios son bienvenidos
                            </p>
                        </div>
                    </div>

                    <div className='row'>
                        <div className='col-md-9'>
                            <form className='contactForm' onSubmit={handleSubmit(onSubmit)}>
                                <div className='mb-3'>
                                    <input id='fullname' name='fullname' {...register('fullname')} type="text" placeholder='Nombre completo' className={`form-control ${errors.fullname ? 'is-invalid' : ''}`}  />
                                    <div className="invalid-feedback">{errors.fullname?.message}</div>
                                </div>
                                <div className='mb-3'>
                                    <input id='email' name='email' type="text" placeholder='Correo electrónico' {...register('email')} className={`form-control ${errors.email ? 'is-invalid' : ''}`} />
                                    <div className="invalid-feedback">{errors.email?.message}</div>
                                </div>
                                <div className='mb-3'>
                                    <textarea id='message' name='message' rows={9} placeholder='Mensaje' {...register('message')} className={`form-control ${errors.message ? 'is-invalid' : ''}`} />
                                    <div className="invalid-feedback">{errors.message?.message}</div>
                                </div>
                                <button disabled={isSubmitting} className='btn btn-outline-primary'>
                                    {isSubmitting && <span className='spinner-border spinner-border-sm mr-1'></span>}
                                    Enviar mensaje
                                </button>
                            </form>
                        </div>
                        <div className='col-md-3'>
                            <h4 className='mb-2 text-xs'>Escríbenos</h4>
                            <p className='mb-4 font-serif'>
                                <a href='mailto:admin@domain.com'>admin@domain.com</a>
                            </p>
                            <h4 className='mb-2 text-xs'>Márcanos</h4>
                            <p className='mb-4 font-serif'>
                                <a href='mailto:admin@domain.com'>00 00 00 00 00</a>
                            </p>
                            <h4 className='mb-2 text-xs'>Visítanos</h4>
                            <p className='mb-4 font-serif'>
                                Calle Gardenia 902, Bahías de Huatulco, Oaxaca, 70987
                            </p>
                        </div>
                    </div>
                </div>
            </section>
        </main>
    )
}
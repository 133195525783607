import { jarallax } from 'jarallax';
import { useEffect } from 'react';

export { Header };

function Header(props) {
    useEffect(() => {
        jarallax(document.querySelectorAll('.jarallax'));
    });

    return (
        <header className="jarallax">
            <img className="jarallax-img" src={"/assets/img/" + props.imageName} alt="" />
            <div className="bg-black-50 pt-10 pt-md-8 pb-7 pb-md-0">
                <div className="container my-auto">
                    <div className="row justify-content-center">
                        <div className="col-12 col-md-8 col-lg-6 text-center">
                            <h1 className="display-6 text-white fw-bold pt-md-15 pb-md-13">
                                {props.title}
                            </h1>
                        </div>
                    </div>
                </div>
            </div>
        </header>
    );
}